<template>
  <div class="events-table">
    <v-simple-table>
      <thead>
        <tr class="events-table__header">
          <th>Contact ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Metric</th>
          <th />
        </tr>
      </thead>
      <tbody class="events-table__content">
        <template v-if="items.length >0">
          <tr
            v-for="(item, i) in items"
            :key="i"
          >
            <td
              class="py-1"
              v-text="item.id"
            />
            <td
              class="py-1"
              v-text="item.firstName"
            />
            <td
              class="py-1"
              v-text="item.lastName"
            />
            <td
              class="py-1"
              v-text="(item.metric * 100).toFixed(2) + '%'"
            />
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <div class="text-center">
                No engagement report found
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'EngagementReportTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.events-table {
  // .v-data-table {
  &__header {
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  &__event-header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    color: #241c15;
  }
  &__event-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: normal;
    color: #66788e;
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      background: inherit !important;
    }
  }
  // }
  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 13px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
    }
  }
  .logo {
    max-height: 95px;
  }

  .panel-button {
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: #66788e;
    text-transform: none;
    background: #ecf1f5 !important;
  }
  .panel-button[aria-expanded=true] {
    color: white;
    background: #2b84eb !important;
    opacity: 1 !important;
  }
}
</style>

<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Engagement Report"
    >
      <template #subheader>
        <div class="d-flex">
          <div class="flex-fill">
            View engagement report
          </div>
          <CustomDateRangePicker
            v-model="dateRanges"
            :format="showDateFormat"
            :max-date="new Date()"
          />
        </div>
      </template>
    </PageHeader>
    <validation-observer ref="reportForm">
      <v-row class="form-container">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            v-model="reportPayload.metric"
            header="Metric"
            placeholder="Select at least a metric"
            item-value="id"
            item-text="text"
            :items="criteria"
            autocomplete
          />
        </v-col>
        <template v-if="reportPayload.metric">
          <v-col
            v-if="!isBannerSelected"
            cols="12"
            sm="8"
            class="py-0"
          >
            <CustomDropdown
              v-model="reportPayload.campaigns"
              header="Campaign"
              item-value="id"
              item-text="text"
              :items="campaigns"
              :multiple="true"
              autocomplete
            />
          </v-col>
        </template>
        <v-col
          v-if="reportPayload.metric"
          cols="12"
          sm="4"
          class="py-0"
        >
          <CustomDropdown
            v-model="reportPayload.creative"
            header="Creative"
            item-value="id"
            item-text="text"
            :items="isBannerSelected ? bannerCreatives : emailCreatives"
            autocomplete
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="py-0"
        >
          <CustomDropdown
            v-model="reportPayload.creativeTag"
            header="Creative Tag"
            item-value="id"
            item-text="text"
            :items="tags"
            autocomplete
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="py-0"
        >
          <CustomDropdown
            v-model="reportPayload.contentPreferenceTag"
            header="Contact Content Preference"
            item-value="id"
            item-text="text"
            :items="tags"
            autocomplete
          />
        </v-col>
      </v-row>
    </validation-observer>
    <v-row class="mt-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button px-13 mr-2"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="reset"
        >
          Reset
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="submit"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>

    <engagement-report-table
      v-if="isLoaded"
      :items="reports"
      class="mt-4"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import dayjs from "dayjs";
import CustomDateRangePicker from "@/sharedComponents/CustomDateRangePicker";
import EngagementReportTable from "@/views/Reports/components/EngagementReportTable";

const showDateFormat = "MM/DD/YYYY";
const systemDateFormat = "YYYY-MM-DD";

export default {
  name: "EngagementReportView",
  metaInfo: {
    title: 'Engagement Report'
  },
  components: {
    EngagementReportTable,
    CustomDateRangePicker,
    CustomDropdown,
    PageHeader,
  },
  data: () => ({
    reportPayload: {
      metric: null,
      campaigns: [],
      creative: null,
      creativeTag: null,
      contentPreferenceTag: null,
    },
    dateRanges: null,
    chartDataType: "impressions",
    showDateFormat,
    systemDateFormat,
    criteria: [
      {
        id: 'email_open_rate',
        text: 'Email Open Rate',
      },
      {
        id: 'email_click_rate',
        text: 'Email Click Rate',
      },
      {
        id: 'click_to_open_rate',
        text: 'Email Click to Open Rate',
      },
      {
        id: 'banner_click_rate',
        text: 'Banner Click Rate',
      },
    ],
    campaigns: [],
    bannerCreatives: [],
    emailCreatives: [],
    tags: [],
    reports: [],
    isLoaded: false,
  }),
  computed: {
    isBannerSelected() {
      return this.reportPayload.metric === 'banner_click_rate';
    },
  },
  created() {
    this.reset();
    this.loadCampaigns();
    this.loadBannerCreatives();
    this.loadEmailCreatives();
    this.loadTags();
  },
  methods: {
    async loadBannerCreatives() {
      const response = await this.$rest.banners.get_collection({
        sort: ['name:asc'],
        isArchived: false
      });
      if (!response.data) {
        return;
      }

      this.bannerCreatives = response.data.items.map((item) => ({
        id: item.id,
        text: item.name,
      }));
    },
    async loadEmailCreatives() {
      const response = await this.$rest.creative.email.get_collection({
        sort: ['name:asc'],
        isArchived: false
      });
      if (!response.data) {
        return;
      }

      this.emailCreatives = response.data.items.map((item) => ({
        id: item.id,
        text: item.name,
      }));
    },
    async loadCampaigns() {
      const response = await this.$rest.campaign.get_collection({
        status: ["live", "completed", "canceled"],
        sort: ["startDate:desc"],
        ignorePagination: true,
        isArchived: false,
      });
      if (!response.data) {
        return;
      }

      this.campaigns = response.data.items
        .map(item => ({
          id: item.id,
          text: item.isArchived ? `${item.name} [Archived]`: item.name,
        }));
    },
    async loadTags() {
      const response = await this.$rest.tags.getCollection({
        fields: ['id', 'name'],
        ignorePagination: true,
        sort: ['name:asc'],
      });
      if (!response.data) {
        return;
      }

      this.tags = response.data.items
        .map(item => ({
          id: item.id,
          text: item.name,
        }));
    },
    reset() {
      this.$refs.reportForm?.resetValidation();

      this.reportPayload = {
        ...{
          metric: null,
          campaigns: null,
          creative: null,
          creativeTag: null,
          contentPreferenceTag: null,
        }
      };

      // default timeFrame is (last month) - today
      const today = dayjs();
      this.$set(this, 'dateRanges', {
        start: today.subtract(1, 'month').toDate(),
        end: today.toDate(),
      });
    },
    async submit() {
      if (!this.reportPayload.metric) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Please select metric',
          color: 'error',
        });
      }

      // pack data
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(this.reportPayload)) {
        if (value === null) {
          continue;
        }

        if (value instanceof Array) {
          for (const data of value) {
            params.append(key + '[]', data);
          }
        } else {
          params.append(key, value);
        }
      }

      if (this.dateRanges?.start && this.dateRanges?.end) {
        params.append('startDate', dayjs(this.dateRanges.start).format(systemDateFormat));
        params.append('endDate', dayjs(this.dateRanges.end).format(systemDateFormat));
      }

      this.isLoaded = false;
      const response = await this.$rest.report.getEngagementReport(params);
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to load the report data. Please try again.',
          color: 'error',
        });
      }

      this.reports = [ ...response.data ];
      this.isLoaded = true;
    },
  },
}
</script>

<style scoped>
.form-container {
  padding-top: 40px;
}
</style>
